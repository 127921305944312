
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
//import MainMenuConfig from "./Menu";
import { useStore } from "vuex";
import DashBorad from "./Menu";
import store from "@/store";
import { VueCookieNext } from "vue-cookie-next";
import {apiEndpoint} from "@/mixin/apiMixin.js"

export default defineComponent({
  mixins:[apiEndpoint],
  name: "kt-menu",
  components: {},
  data() {
    return {
      MainMenuConfig: [
        {
          heading: "menu",
          route: "/crafted",
          pages: [
            {
              heading: "dashboard",
              route: "/dash",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
            },
          ],
        },
      ],
    };
  },
  async created() {
    //await this.Dashboard();
  },
  methods: {
   
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
